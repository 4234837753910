import { FormContainer  } from '../../components'
import { Helmet } from 'react-helmet'

const Term = () => {


  return (
    <FormContainer>
      <Helmet>
        <title>Término de Uso del Aplicativo</title>
        <meta property='og:title' content='Término de Uso del Aplicativo' key='title' />
      </Helmet>
      <h4 className='fw-light font-monospace text-center'></h4>
      

      <form >
      <div className='row'>
          
          
          <div className='col-12'>
            <hr></hr>
          </div>
      </div>
        
      </form>
    </FormContainer>
  )
}

export default Term
