import { FaPenAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'

const ViewOrganizations = ({
  data,
  editHandler,
  isAdmin
}) => {

  const headers = [
    { title: 'Nombre', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Identificación', prop: 'identification', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Descripción', prop: 'description', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Es Público?', prop: `isPublic`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Activo', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]


  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          {isAdmin == true ?
            <p> Listado de organizaciones <sup className='fs-6'>[{data && data.length}] </sup></p>
            : 'Configuraciones de la Organización'
          }
        </h3>
        {isAdmin ? (
          <button
            className='btn btn-outline-primary btn-sm shadow my-2'
            data-bs-toggle='modal'
            data-bs-target='#organizationModal'
            onClick={() => document.getElementById("icon-tab-0").click()}
            data-bs-placement="top" title="Clic para agregar una Organización"
          >
            Agregar una organización
          </button>) : ''}

      </div>


      <DatatableWrapper body={data} headers={headers} paginationOptionsProps={{
        initialState: {
          rowsPerPage: 15,
          options: [5, 10, 15, 20]
        }
      }} >
        <Row className="mb-4">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter placeholder={'Ingrese información de la empresa a encontrar'} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions labels={{ beforeSelect: `Filas por página / Empresas encontradas ${data.length}` }} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
          </Col>
        </Row>
        <hr></hr>
        <Table>
          <TableHeader />
          <TableBody>
            {(rows) =>
              rows.length === 0 ? (
                <EmptyTablePlaceholder noResultsLabel={'Usuarios no encontrados'} />
              ) : (
                rows.map((rowData, rowIdx) => (
                  <tr key={rowIdx} className='text-center'>
                    <td>{rowData.name}</td>
                    <td>{rowData.identification}</td>
                    <td>{rowData.description}</td>
                    <td>
                      {rowData.isPublic ? (
                        <FaCheckCircle className='text-success' />
                      ) : (
                        <FaTimesCircle className='text-danger' />
                      )}

                    </td>
                    <td>
                      {rowData.active ? (
                        <FaCheckCircle className='text-success' />
                      ) : (
                        <FaTimesCircle className='text-danger' />
                      )}

                    </td>

                    <td>
                      <div className='btn-group'>

                        <button
                          className='btn btn-primary btn-sm rounded-pill'
                          onClick={() => editHandler(rowData)}
                          data-bs-toggle='modal'
                          data-bs-target='#organizationModal'
                          data-bs-placement="top" title="Editar Organización">
                          <FaPenAlt />
                        </button>

                      </div>
                    </td>
                  </tr>

                )
                )
              )
            }
          </TableBody>
        </Table>
      </DatatableWrapper>

    </div>
  )
}

export default ViewOrganizations
