import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Página Principal</title>
        <meta property='og:title' content='Página Principal' key='title' />
      </Helmet>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: 'auto' }}
      >
        <span className='text-muted'></span>
      </div>
    </>
  )
}

export default Home
