import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useNetworksHook from '../../../api/networks'
import { useLocation  } from 'react-router-dom'

import FormNetworks from '../../../components/networks/FormNetworks'
import ViewNetworks from '../../../components/networks/ViewNetworks'

import {
  Message,
} from '../../../components'

const Networks = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  var [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [subNetwork, setSubNetwork] = useState(undefined)
  const [members, setMembers] = useState([])
  var membersList = []
    
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const networkId = searchParams.get('network_id')
  const branchId = searchParams.get('branch_id')


  const { getNetworks, updateNetwork, deleteNetwork } =
    useNetworksHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  
  const { data, isLoading, isError, error, refetch } = getNetworks

  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    //isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateNetwork

  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteNetwork



  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  useEffect(() => {

    const userRoles = localStorage.getItem('userRole') ? JSON.parse(localStorage.getItem('userRole')): null
    if(userRoles.isSuperAdmin) {
      setIsSuperAdmin(true)
    } else {
      setIsSuperAdmin(false)
    } 
    refetch()

  }, [data])


  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    mutateAsyncDelete(id)
  }

  const submitHandler = (data) => {
    if(edit){
      mutateAsyncUpdate({
        _id: id,
        name: data.name,
        type: data.type,
        active: data.active,
      })
    }
  }

  const editHandler = (subNetwork) => {

    refetch()
    setId(networkId)
    setSubNetwork(subNetwork)
    setEdit(true) 

    for (let i = 0; i < subNetwork.members.length; i++) {
      membersList.push({
        _id: subNetwork.members[i].user._id,
        typeIdentification: subNetwork.members[i].user.typeIdentification,
        identification: subNetwork.members[i].user.identification,
        birthday: subNetwork.members[i].user.birthday,
        sex: subNetwork.members[i].user.sex,
        name: subNetwork.members[i].user.name,
        lastname: subNetwork.members[i].user.lastname,
        email: subNetwork.members[i].user.email,
        phone: subNetwork.members[i].user.phone,
        active: subNetwork.members[i].active,  
      })
    }
    setMembers(membersList)

  }

  return (
    <>
      <Helmet>
        <title>Redes</title>
        <meta property='og:title' content='Redes' key='title' />
      </Helmet>
      {isSuccessDelete && ( <Message variant='success'>Red eliminada correctamente.</Message>)}
      
      { isError && <Message variant='danger'>{error}</Message>}

      {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
     
      <FormNetworks
        id={networkId}
        edit={edit}
        branchId={branchId}
        subNetwork={subNetwork}
        members={members}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
        isSuperAdmin={isSuperAdmin}
      />




      <ViewNetworks
          data={data}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          formCleanHandler={formCleanHandler}
          isLoadingDelete={isLoadingDelete}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
          isSuperAdmin={isSuperAdmin}
        />
    </>
  )
}

export default Networks
