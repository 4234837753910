
import { FormContainer, Message } from '../../components'
import accountValidationHook from '../../api/account-validation'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { AiOutlineKey } from "react-icons/ai"
import { FiEyeOff, FiEye } from "react-icons/fi"
import useAuthHook from '../../api/auth'

const AccountValidation = () => {

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  var [messageValueInitialPassword, setMessageValueInitialPassword] = useState('')
  var  messageValidation = ''
  var { postInitialPassword, postLogout } = useAuthHook()
  var { isLoading, mutateAsync, error: errorInitialPassword } = postInitialPassword
  var [showValidation, setShowValidation] = useState(false)
  const [remainingTime, setRemainingTime] = useState(10)
  const [errorAccount, setErrorAccount] = useState('')

  const [type, setType] = useState('password')
  const [icon, setIcon] = useState(FiEyeOff)
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(FiEye);
      setType('text')
    } else {
      setIcon(FiEyeOff)
      setType('password')
    }
  }
  const [typeConfirm, setTypeConfirm] = useState('password')
  const [iconConfirm, setIconConfirm] = useState(FiEyeOff)
  const handleToggleConfirm = () => {
    if (typeConfirm === 'password') {
      setIconConfirm(FiEye);
      setTypeConfirm('text')
    } else {
      setIconConfirm(FiEyeOff)
      setTypeConfirm('password')
    }
  }


  var { getAccountValidation } = accountValidationHook({
    token: searchParams.get('token'),
    id: searchParams.get('id'),
    origin: searchParams.get('origin'),
  })

  var { mutateAsync: mutateAsyncLogout } = useMutation(postLogout)

  var { data } = getAccountValidation

  let interval

  useEffect(() => {
    console.log('data')
    console.log(data)
    if(data!= undefined) {
      try {
        
        mutateAsyncLogout({})
        cleanPassword()  

        if(data.error) {
          setShowValidation(true)
          setErrorAccount(data.error)

          interval = setInterval(() => {
            setRemainingTime(
              (prevRemainingTime) =>
                prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 // Reduce por 1 segundo
            )
          }, 1000)

          if(remainingTime == 0) {
            navigate('/auth/login')
          }

          return () => {
            clearInterval(interval)
          }
        }
      } catch (error) {
        console.log(error)
        setShowValidation(true)
      }
    }

  }, [data, remainingTime])

  const [input, setInput] = useState({
    id: searchParams.get('id'),
    password: '',
    confirmPassword: ''
  })

  const [error, setError] = useState({
    id: '',
    password: '',
    confirmPassword: ''
  })

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {

        case "password":
          if (!value) {
            stateObj[name] = "Ingrese la Contraseña.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Contraseña y Confirmar Contraseña no coinciden.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Ingrese la Confirmación de la Contraseña.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Contraseña y Confirmar Contraseña no coinciden.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }


  var changePassword = async () => {

    const password = input.password
    const tokenPass = searchParams.get('tokenPass')
    const id = searchParams.get('id')

    try {
    
      var returnInitialPassword = await mutateAsync({ password, tokenPass, id })

      if (returnInitialPassword.message) {
        setShowValidation(true)
        setMessageValueInitialPassword(returnInitialPassword.message)
        let count = 10
        interval = setInterval(() => {
          setRemainingTime(
            (prevRemainingTime) =>
              prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 // Reduce por 1 segundo
          ), count = count -1 , count == 0 ?navigate('/auth/login'):'', count == 0 ?clearInterval(interval):'' 
        }, 1000)

      }

    } catch (error) {
      console.log(error)  
    }
  
  }

  const cleanPassword = () => {
    setInput({ id: searchParams.get('id'), password: '', confirmPassword: '' })
    setError({ id: '', password: '', confirmPassword: '' })
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <FormContainer>
      <Helmet>
        <title>{ showValidation ?  'Validación de Cuenta': 'Ingreso Obligatorio de Contraseña'}</title>
        <meta property='og:title' content='Proceso de validación de cuenta' key='title' />
      </Helmet>
      <center><h3 className=''>{ showValidation ?  'Validación de Cuenta': 'Ingreso Obligatorio de Contraseña'}</h3></center>
      <hr></hr>

      {messageValueInitialPassword && (<Message variant='success'>{messageValueInitialPassword}</Message>)}
      {messageValidation!='' ? <Message variant='danger'>{messageValidation}</Message> :''}
      {errorInitialPassword ? <Message variant='danger'>{errorInitialPassword}</Message> : ''}

      <form >

      { showValidation ? (
        <div className="card">
          <div className="card-header text-center">
            <b> {errorAccount} </b>
          </div>
          <div className="card-body">
            <center><span className='spinner-border spinner-border-sm' /></center>
            Se redireccionará en <b>{millisToMinutesAndSeconds(remainingTime * 1000)}</b> segundos a la página de inicio para que acceda al sistema.
          </div>
        </div>
       ): 
       <div className="card">
       <div className="card-header text-center">
         <b>Ingrese la contraseña teniendo los siguientes parámetros:</b> &nbsp;
         Mínimo 6 caracteres, al menos 1 letra minúscula, 1 letra mayúscula y 1 número.<br></br>
         <b>No se permiten caracteres especiales cómo: +,-,$,*,/,_,etc</b>

       </div>
       <div className="card-body">
         <div className="row">
           <div className='col-md-2'></div>
           <div className='col-md-8'>
           <div className='mb-3 flex'>
             <input
               type={type}
               name="password"
               placeholder='Ingrese la Contraseña'
               value={input.password}
               onChange={onInputChange}
               onBlur={validateInput} className='form-control' ></input>
             <center>{error.password && <span className='err' style={({ color: 'red' })}>{error.password}</span>}</center>
             <span className="flex justify-around items-center" onClick={handleToggle}>
                              <p className="input-password">{icon}</p>
                            </span>
                          </div>
           </div>
           <div className='col-md-2'></div>
         </div>
         &nbsp;
         <div className="row">
           <div className='col-md-2'></div>
           <div className='col-md-8'>
           <div className='mb-3 flex'>
             <input
               type={typeConfirm}
               name="confirmPassword"
               placeholder='Confirme la Contraseña'
               value={input.confirmPassword}
               onChange={onInputChange}
               onBlur={validateInput} className='form-control'></input>
               <span className="flex justify-around items-center" onClick={handleToggleConfirm}>
                              <p className="input-password">{iconConfirm}</p>
                            </span>
                          </div>
             <center>{error.confirmPassword && <span className='err' style={({ color: 'red' })}>{error.confirmPassword}</span>}</center>
           </div>
           <div className='col-md-2'></div>
         </div>
         &nbsp;
         <div className="row">
           <div className='col-md-2'></div>
           <div className='col-md-8 text-center'>
             <button type='button' className='btn btn-primary form-control' disabled={isLoading} onClick={changePassword}>
               {isLoading ? (
                 <span className='spinner-border spinner-border-sm' />
               ) : (
                 ''
               )} <AiOutlineKey /> &nbsp; Ingresar Contraseña
             </button>
           </div>
           <div className='col-md-2'></div>
         </div>
       </div>
     </div>
        
        }

      </form>
    </FormContainer>
  )
}

export default AccountValidation
