import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useEventIndividualsHook from '../../../api/event-individuals'
import {
  Spinner,
  Pagination,
  Message,
} from '../../../components'
import FormEventIndividuals from '../../../components/event-individuals/FormEventIndividuals'
import ViewEventIndividuals from '../../../components/event-individuals/ViewEventIndividuals'
//import useSound from "use-sound"


const EventIndividuals = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState('')
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [imagen, setImagen] = useState('')
  const [usuario, setUsuario] = useState('')
  const [totalRow, setTotalRow] = useState(0)

  const { getEventIndividuals } = useEventIndividualsHook({ page, q })

  //const [play] = useSound(process.env.PUBLIC_URL + "/beep.mp3", { interrupt: true })
  

  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  var { data, isLoading, isError, error, refetch } = getEventIndividuals

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
  
    if(data!= undefined){
      
      setTotalRow(data.count)
      console.log('Total antes : '+data.count)
    }

    //refetch()
    if(data!= undefined){
      console.log('Total despues a: '+data.count +'Total despues totalRow:'+totalRow)
      console.log('Total despues 2 : '+totalRow)
      if(data.count > totalRow){
        //play()
      } 
    }
 
    return () => {
      data = null
    }
    
  }, [page][q])

  
  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const editHandler = (event) => {

    setId(event._id)
    setEdit(true)
    setValue('name', event.name)
    setUsuario(event.user.name +' '+ event.user.lastname)
    setValue('user', event.user.name +' '+ event.user.lastname)
    setLatitude(event.location.coordinates[0])
    setLongitude(event.location.coordinates[1])
    setValue('service', event.service.name)
    setValue('state', event.state)
    setValue('subNetwork', event.subNetwork.name)
    setImagen(event.user.image)
    setValue('locationName', event.locationName)
    setValue('locationSector', event.locationSector)
    setValue('phone', event.user.phone)
    setValue('detail', event.detail)
  }

  return (
    <>
      <Helmet>
        <title>Eventos</title>
        <meta property='og:title' content='Eventos' key='title' />
      </Helmet>
      
      <FormEventIndividuals
        id={id}
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        register={register}
        watch={watch}
        error={error}
        longitude={longitude}
        latitude={latitude}
        imagen={imagen}
        usuario={usuario}
      />

      <div className='ms-auto text-end'>
        <Pagination data={data} setPage={setPage} />
      </div>

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <ViewEventIndividuals
          data={data}
          editHandler={editHandler}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}
    </>
  )
}

export default EventIndividuals
