import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'

const url = '/api/auth/event-organizations'

const queryKey = 'event-organizations' 

export default function useEventOrganizationsHook(props) {
  const { page = 1, q = '', limit = 25 } = props
  const queryClient = useQueryClient()

  const getEventOrganizations = useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
    { retry: 0 }
  )

 
  const updateEventOrganization = useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  
  const postEventOrganization = useMutation(
    async (obj) => await dynamicAPI('post', "/api/auth/postEventExternal" , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postEventOrganizationList = useMutation(
    async (obj) => await dynamicAPI('post', "/api/auth/event-organizations/postEventOrganizationList" , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(["event-organizations"]),
    }
  )

  const postEventOrganizationBranchList = useMutation(
    async (obj) => await dynamicAPI('post', "/api/auth/event-organizations/postEventOrganizationBranchList" , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(["event-organizations"]),
    }
  )

  const postEventOrganizationSubNetworkList = useMutation(
    async (obj) => await dynamicAPI('post', "/api/auth/event-organizations/postEventOrganizationSubNetworkList" , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries(["event-organizations"]),
    }
  )

  return {getEventOrganizations, updateEventOrganization, postEventOrganization, postEventOrganizationList,postEventOrganizationBranchList, postEventOrganizationSubNetworkList }
}
