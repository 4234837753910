import dynamicAPI from './dynamicAPI'
import { useQuery } from 'react-query'

const url = '/api/auth'

export default function accountValidationUserProfileHook(props) {

  const { token = '', id = '', origin='' } = props
  const queryKey = 'account_validation'

  const getAccountValidationUserProfile = useQuery(
    queryKey,
    async () => 
    await dynamicAPI('get', `${url}/account-validation-user-profile?token=${token}&id=${id}&origin=${origin}`, {}),
    { retry: 0 }
  )

  return  {
    getAccountValidationUserProfile
  }    
  
}


