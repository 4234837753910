import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useLocation  } from 'react-router-dom'

const url = '/api/auth/networks'

const queryKey = '' 

export default function useNetworksHook(props) {
  const { page = 1, id, q = '', limit = 25 } = props
  const queryClient = useQueryClient()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const network_id = searchParams.get('network_id')

  const getNetworks = network_id!=undefined ? useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}&network_id=${network_id != undefined? network_id:'' }`, {}),
    { retry: 0 }
  ):''

  const getNetworkById = useQuery(
    queryKey,
    async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  )

  const updateNetwork = network_id!=undefined ? useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${network_id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  ):''

  const deleteNetwork = useMutation(
    async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )


  return {
    getNetworks,
    updateNetwork,
    deleteNetwork,
    getNetworkById,
  }
}
