import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useBranchLicensesHook from '../../../api/branch-licenses'
import FormBranchLicenses from '../../../components/branch-licenses/FormBranchLicenses'
import ViewBranchLicenses from '../../../components/branch-licenses/ViewBranchLicenses'

import {
  Message,
} from '../../../components'

const BranchLicenses = () => {
  const [page, setPage] = useState(1)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  const [id, setId] = useState(null)
  const [branchLicense, setBranchLicense] = useState(null)

  

  const { getBranchLicenses, deleteBranchLicense } =
    useBranchLicensesHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  
  const { data, isLoading, isError, error, refetch } = getBranchLicenses



  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteBranchLicense



  const formCleanHandler = () => {
    setEdit(false)
    reset()
    setBranchLicense(null)
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    mutateAsyncDelete(id)
  }

  const editHandler = (branchLicense) => {
    refetch()
    setId(branchLicense._id)
    setBranchLicense(branchLicense)
    setEdit(true)
  }

  return (
    <>
      <Helmet>
        <title>Historial de Licencias</title>
        <meta property='og:title' content='Licencias' key='title' />
      </Helmet>
      {isSuccessDelete && (
        <Message variant='success'>
          Contrato eliminado correctamente.
        </Message>
      )}
      {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}

      { isError && <Message variant='danger'>{error}</Message>}

      <FormBranchLicenses
        id={id}
        edit={edit}
        branchLicense={branchLicense}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        watch={watch}
        error={error}
      />

        <ViewBranchLicenses
          data={data}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          isLoadingDelete={isLoadingDelete}
          formCleanHandler={formCleanHandler}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      

    </>
  )
}

export default BranchLicenses
