import Navigation from './Navigation'
import Footer from './Footer'

export default function Layout({ children }) {

  const pathname  = window.location.pathname
  var completePage = true

  // Paginas que no tendrán la cabecera del menu ni el pie de pagina
  if(pathname.includes('branches') || pathname.includes('networks')  || pathname.includes('branch-licenses') || pathname.includes('map-branches') ) {
    completePage = false
  } 

  return (
    <>
       {completePage == true ? ( <Navigation /> ) : ''}
      <main className='container py-2' style={{ height: 'auto', minHeight: "510px" }}>
        {children}
      </main>
      {completePage == true ? ( <Footer /> ) : ''}
    </>
  )
}
