import React, { useEffect } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
//import styles from'./estilos.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const initParticles = async () => {
	await initParticlesEngine(async (engine) => {
		await loadSlim(engine);
	});
};

// Llamamos a la función de inicialización una vez al cargar el módulo
initParticles();

const FormContactForms = () => {
	const particlesLoaded = (container) => {
		console.log(container);
	};

	useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


	const options = {
		fullScreen: {
			enable: false,  // Habilitar fullScreen para ocupar todo el contenedor
			zIndex: -1,
		},
		background: {
			image: 'url(../../bgrojo.jpg)',
			size: 'cover',
			repeat: 'no-repeat'
		},
		fpsLimit: 120,

		particles: {
			color: {
				value: '#78746A',
			},
			links: {
				color: '#78746A',
				distance: 150,
				enable: true,
				opacity: 0.5,
				width: 1,
			},
			move: {
				direction: 'none',
				enable: true,
				outModes: {
					default: 'bounce',
				},
				random: false,
				speed: 2,
				straight: false,
			},
			number: {
				density: {
					enable: true,
				},
				value: 350,
			},
			opacity: {
				value: 0.5,
			},
			shape: {
				type: 'circle',
			},
			size: {
				value: { min: 1, max: 5 },
			},
		},
		detectRetina: true,
	};

	return (
		<>
			<div className="position-relative" style={{ height: '490px' }}>
				<Particles
					id="tsparticles"
					particlesLoaded={particlesLoaded}
					options={options}
				/>
				<div className="position-relative z-index-1 container h-100 d-flex align-items-center" data-aos="fade-up">
					<div className="col-lg-7 offset-lg-5 order-lg-1 d-flex flex-column justify-content-start text-left caption p-4 text-white">
						<h1>Controla actos ilícitos con
							<p>ALERTA GLOBAL.</p>
						</h1>
						<h4>
							Protege a tus seres queridos con nuestra <br/> aplicación de seguridad ciudadana
						</h4>
						<div>
							<a href="https://www.facebook.com/Constecoin/" target='_blank' rel='noopener noreferrer' className="me-3" ><AiFillFacebook/></a>
							<a href="https://www.tiktok.com/@constecoin?lang=es" target='_blank' rel='noopener noreferrer' className="me-3"><FaTiktok/></a>
							<a href="https://www.instagram.com/constecoin/" target='_blank' rel='noopener noreferrer' className="me-3"><AiFillInstagram/></a>
						</div>
						<div className="d-flex justify-content-start mt-3">
							<a href='https://play.google.com/store/apps/details?id=com.alerta.constecoin' target='_blank' rel='noopener noreferrer' className="me-3">
								<img src='/playStore.png' alt='Download from Play Store' className="img-fluid iconStore" />
							</a>
							<a href='https://www.apple.com/app-store/' target='_blank' rel='noopener noreferrer'>
								<img src='/appStore.png' alt='Download from App Store' className="img-fluid iconStore" />
							</a>
						</div>
					</div>
				</div>
			</div>

		</>

	);
};

export default FormContactForms;
