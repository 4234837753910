import { useEffect } from 'react'
import { FormContainer, Message } from '../../components'
import { useForm } from 'react-hook-form'
import { useParams, Link } from 'react-router-dom'
import { inputPassword } from '../../utils/dynamicForm'
import useAuthHook from '../../api/auth'
import { Helmet } from 'react-helmet'
import { AiOutlineKey , AiOutlineHome  } from "react-icons/ai"

const ResetPassword = () => {
  const { postResetPassword } = useAuthHook()
  const param = useParams()


  const { resetToken } = param

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      admin: false,
      user: false,
    },
  })

  const { isLoading, isError, error, isSuccess, mutateAsync } =
    postResetPassword

  useEffect(() => {
    if (isSuccess) {
      resetForm()
    }
  }, [isSuccess])


  const submitHandler = (data) => {
    const password = data.password
    mutateAsync({ password, resetToken })
  }

  return (
    <FormContainer>
      <Helmet>
        <title>Resetear Contraseña</title>
        <meta property='og:title' content='Resetear' key='title' />
      </Helmet>
      <center><h3 className=''>Resetear Contraseña</h3></center>
      {isSuccess && (
        <Message variant='success'>Contraseña actualizada exitosamente</Message>
      )}

      {isError && <Message variant='danger'>{error}</Message>}

      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='row'>
          <div className="alert alert-primary" role="alert">
            Ingrese los valores requeridos para realizar el proceso de cambio de contraseña.
            Mínimo 6 caracteres, al menos 1 letra minúscula, 1 letra mayúscula y 1 número<br></br>
            <b>No se permiten caracteres especiales cómo: +,-,$,*,/,_,etc</b>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            {inputPassword({
              register,
              errors,
              label: 'Contraseña',
              name: 'password',
              minLength: true,
              isRequired: true,
              placeholder: 'Contraseña',
            })}    
          </div>
          <div className='col-md-2'></div>
        </div>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            {inputPassword({
              register,
              errors,
              watch,
              name: 'confirmPassword',
              label: 'Confirmar Contraseña',
              validate: true,
              minLength: true,
              placeholder: 'Confirmar Contraseña',
            })}  
          </div>
          <div className='col-md-2'></div>
        </div>
       
        <div className='row'>
          <div className='col-md-6'>
          <button
              type='submit'
              className='btn btn-primary form-control '
              disabled={isLoading}
            >
              {isLoading ? (
                <span className='spinner-border spinner-border-sm' />
              ) : (
                ''
              )} <AiOutlineKey  /> &nbsp; Cambiar
            </button>
          </div>
          <div className='col-md-6'>
            <Link to='/auth/login' className='btn btn-primary form-control' >
              <AiOutlineHome /> &nbsp;    Retornar Página Inicio
            </Link>
          </div>    
        </div>        
      </form>
    </FormContainer>
  )
}

export default ResetPassword
