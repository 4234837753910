import axios from 'axios'
import { config } from '../utils/customLocalStorage'

const dynamicAPI = async (method, url, obj = {}) => {

  var urlComplete = ''

    if(location.protocol == 'http:'){
      urlComplete = location.protocol+'//'+location.hostname+ `:5000${url}`
    }else{
      urlComplete = location.origin+`${url}`
    }
  
  
  try {
    switch (method) {
      case 'get':
        return await axios
          .get(urlComplete, config())
          .then((res) => res.data)

      case 'post':
        return await axios
          .post(urlComplete, obj, config())
          .then((res) => res.data)

      case 'put':
        return await axios
          .put(urlComplete, obj, config())
          .then((res) => res.data)

      case 'delete':
        return await axios
          .delete(urlComplete, config())
          .then((res) => res.data)
    }
  } catch (error) {
    throw error.response.data.error
  }
}

export default dynamicAPI
