import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'

const url = '/api/auth/users'

const queryKey = 'users'

export default function useUsersHook(props) {
  const { page = 1, q = '', limit = 25 } = props
  const queryClient = useQueryClient()
 
  const getUsers = useQuery( 
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
    { retry: 0 }
  )


  const updateUser = useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const deleteUser = useMutation(
    async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postUser = useMutation(
    async (obj) => await dynamicAPI('post', url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postUserByInput = useMutation(
     async (obj) => await dynamicAPI('post', "/api/auth/users/postUserByInput" , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )
  
  const postUserById = useMutation(
    async (obj) => await dynamicAPI('post', "/api/auth/users/postUserById" , obj),
   {
     retry: 0,
     onSuccess: () => queryClient.invalidateQueries([queryKey]),
   }
 )
 
  return { getUsers, updateUser, deleteUser, postUser, postUserByInput, postUserById }
}
