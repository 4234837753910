import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { FormContainer, Message } from '../../components'
import { useForm } from 'react-hook-form'
import useAuthHook from '../../api/auth'
import useUserRolesHook from '../../api/userRoles'
import { inputPassword } from '../../utils/dynamicForm'
import useAuth from '../../hooks/useAuth'
import { Helmet } from 'react-helmet'
import { FaUserPlus } from 'react-icons/fa'

const Login = () => {
  let [searchParams] = useSearchParams()
  const pathName = searchParams.get('next') || '/'
  const navigate = useNavigate()
  const [identification, setIdentification] = useState('')

  const { auth } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { setAuth } = useAuth()

  const { postLogin } = useAuthHook()

  const { isLoading, isError, error, mutateAsync, isSuccess, data } = postLogin

  const { postUserRoleById } = useUserRolesHook({
    page: 1,
    q: '',
    limit: 10000000,
  })

  const {
    mutateAsync: userRoleMutateAsync,
    data: userRole,
    error: errorUserRole,
    isError: isErrorUserRole,
  } = postUserRoleById

  useEffect(() => {
    if (isSuccess) {
      userRoleMutateAsync(data._id)
      if (userRole) {
        localStorage.setItem('userRole', JSON.stringify(userRole))
        localStorage.setItem('userInfo', JSON.stringify(data))

        setAuth({
          userInfo: data,
          userRole: userRole,
        })
        navigate(pathName)
      }
    }
  }, [isSuccess, userRole])

  useEffect(() => {
    auth?.userInfo && navigate('/')
  }, [navigate])

  const submitHandler = async (data) => {
    await mutateAsync({identification: identification, password: data.password })
  }

  return (
    <>
      <FormContainer>
        <Helmet>
          <title>Alerta Global - Constecoin</title>
          <meta property='og:title' content='Alerta Global - Constecoin' key='title' />
        </Helmet>
        <h3 className='fw-light font-monospace text-center'>Ingreso al Sistema</h3>
        <br/>
        {isError && <Message variant='danger'>{error}</Message>}
        {isErrorUserRole && <Message variant='danger'>{errorUserRole}</Message>}

        <form onSubmit={handleSubmit(submitHandler)}>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">

              <input
                { ...register("identification",  { required: `* Campo requerido` })}
                type='text'
                name='identification'
                placeholder="Correo electrónico o número telefónico"
                value={identification}
                onChange={(event) => setIdentification(event.target.value)}
                className='form-control'
              />
              {errors && errors['identification'] && (
                <span className='text-danger'>{errors['identification'].message}</span>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              {inputPassword({
                register,
                errors,
                label: 'Contraseña',
                name: 'password',
                placeholder: 'Contraseña',
              })}
            </div>
            <div className="col-md-2"></div>  
          </div>        
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <button
                type='submit'
                className='btn btn-primary form-control '
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className='spinner-border spinner-border-sm' />
                ) : (
                  'Ingresar'
                )}
              </button>
            </div>
            <div className="col-md-2"></div>  
          </div>          
        </form>
        <br></br>
        <div className='row'>
          <div className="col-md-2"></div>
          <div className="col-md-8 text-center">
            <Link to='/auth/forgot-password' className='ps-1'>
              Olvidó la Contraseña?
            </Link>
          </div>
          <div className="col-md-2"></div>  
        </div> 
        <hr></hr>
        <div className='row'>
          <div className="col-md-2"></div>      
          <div className="col-md-8 text-center">
            <Link to='/auth/register' className='btn btn-success form-control' aria-current='page'>
              <FaUserPlus className='mb-1' /> Registro Nuevos Usuarios
            </Link>
          </div>  
          <div className="col-md-2"></div>  
        </div>
      </FormContainer>
    </>
  )
}

export default Login
