import React, { useState, useRef, useEffect } from "react"
import { FaPenAlt } from 'react-icons/fa'
import moment from 'moment'
import useEventOrganizationsHook from '../../api/event-organizations'
import useBranchesHook from '../../api/branches'
import socketIO from 'socket.io-client'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'

const ViewEvents = ({
  editHandler,
  organizationBranches,
  organizations,
  networkSubNetworks,
}) => {

  const { postEventOrganizationList, postEventOrganizationBranchList, postEventOrganizationSubNetworkList } = useEventOrganizationsHook({ page: 1 })
  var [organization, setOrganization] = useState('')
  var [branch, setBranch] = useState('')
  var [subNetwork, setSubNetwork] = useState('')
  var { postBranchesByOrganizationUnique } = useBranchesHook({})
  var [branches, setBranches] = useState([])
  var [subNetworks, setSubNetworks] = useState([])
  var [events, setEvents] = useState([])


  const URL_SOCKET = `${process.env.REACT_APP_URL_DOMAIN_SOCKET}`
  const socketRef = useRef(null)

  var isAdmin = false
  var seeAllOrganization = false
  var seeAllBranch = false
  var memberBranch = false
  var [seeAllMember, setSeeAllMember] = useState(false)

  var audioRef = useRef(null)

  const { mutateAsync: mutateAsyncEventOrganizationList } = postEventOrganizationList
  const { mutateAsync: mutateAsyncEventOrganizationBranchList } = postEventOrganizationBranchList
  var { mutateAsync: mutateAsyncBranchesUniqueByOrganization } = postBranchesByOrganizationUnique
  var { mutateAsync: mutateAsyncEventOrganizationSubNetworkList } = postEventOrganizationSubNetworkList


  useEffect(() => {
    // Componente Montado componentDidMount
    socketRef.current = socketIO.connect(URL_SOCKET,
      {
        'sync disconnect on unload': true,
        auth: {
          authorization: `Bearer ${process.env.REACT_APP_JWT_SECRET_SOCKETS}`
        }
      }
    )

    socketRef.current.on("connect", () => {
      if (socketRef.current.id != undefined) {
        console.log('Cliente conectado: ' + socketRef.current.id)
      }
    })

    // Errores en la conexion con el socket
    socketRef.current.on("connect_error", (err) => {
      console.log(err.message); // not authorized
    })

    // Componente debera ser desmontado componentWillUnmount
    return () => {
      if (socketRef.current.id != undefined) {
        console.log("Cliente ha sido desconectado: " + socketRef.current.id)
        socketRef.current.disconnect()
      }
    }

  }, [])



  const headers = [
    { title: 'Evento', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Fecha del Evento', prop: 'createdAt', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Solicitante ayuda', prop: `user`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Estado', prop: 'state', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Sucursal', prop: 'branch.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Servicios', prop: 'service.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Organización', prop: 'organization.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]

  useEffect(() => {
    // Inicializar el objeto de audio al montar el componente
    audioRef.current = new Audio("/beep.mp3")
    audioRef.current.load(); // Precarga el audio

  }, [])

  // Metodo para agregar las organizaciones y cargar los eventos (sockets) segun rol
  const handleChangeOrganizations = async (organizationId) => {

    var branchlist = []

    if (organizationId != "") {

      // Actualizamos el rol por organizacion
      setOrganization(organizationId)

      var eventTotalList = []

      
      getRoleBranch(organizationId)
      
      // Si no es member_branch
      if (memberBranch == false) {
        if (isAdmin == true || seeAllOrganization == true) {

          var socketConnected = false

          // 1. Consulta de todos los eventos de la organizacion
          var existEventOrganizacionList = []
          existEventOrganizacionList = await mutateAsyncEventOrganizationList({ organizationId: organizationId })
          eventTotalList.push(existEventOrganizacionList)
          setEvents(eventTotalList)

          socketRef.current.on(`event/${organizationId}`, function (data) {


            setEvents((historialPrevio) => [
              ...historialPrevio,
              data
            ])

            socketConnected = true
            try {
              audioRef.current.play()
            } catch (error) {
              console.log("Error al reproducir el audio: " + error)
            }

          })

          socketRef.current.on(`eventupdate/${organizationId}`, function (data) {
            let eventUpdate = data
            if (eventUpdate !== undefined && eventUpdate !== '') {
              setEvents((prevEvents) =>
                prevEvents.filter(eventObject => eventObject._id !== eventUpdate))
            }
          })
        }

        if (socketConnected == false) {
          setEvents(eventTotalList[0].data)
        }

        if (isAdmin == true || seeAllOrganization == true) {

          var existEventOrganizacionBranchList = []
          existEventOrganizacionBranchList = await mutateAsyncBranchesUniqueByOrganization({ organization_id: organizationId })

          for (let i = 0; i < existEventOrganizacionBranchList.data.length; i++) {
            branchlist.push({ _id: existEventOrganizacionBranchList.data[i]._id, name: existEventOrganizacionBranchList.data[i].name })
          }
        }

        if (seeAllBranch == true) {
          for (let i = 0; i < organizationBranches.length; i++) {
            if (organizationBranches[i].organization_id == organizationId) {
              branchlist.push({ _id: organizationBranches[i]._id, name: organizationBranches[i].name })
            }
          }
        }
      } else {
       
        // Ponemos datos de las sucursales por organizacion
        for (let i = 0; i < organizationBranches.length; i++) {
          if (organizationBranches[i].organization_id == organizationId) {
            branchlist.push({ _id: organizationBranches[i]._id, name: organizationBranches[i].name })
          }
        }

      }
      setBranches(branchlist)
    } else {
      setOrganization('')
      setEvents([])
      setBranches('')
    }
  }

  // Metodo para agregar las sucursales y cargar los eventos (sockets) segun rol
  const handleChangeBranches = async (branchId) => {

    setBranch(branchId)

    var eventTotalList = []

    if (branchId == "") {
      setEvents([])
    } else {
      setEvents([])

      // Actualizamos el rol por organizacion
      getRoleBranch(organization)

      if (memberBranch == false) {
        // 1. Consulta de todos los eventos de la organizacion y de la sucursal
        var existEventOrganizacionBranchList = []
        existEventOrganizacionBranchList = await mutateAsyncEventOrganizationBranchList({ organizationId: organization, branchId: branchId })
        eventTotalList.push(existEventOrganizacionBranchList)
        setEvents(eventTotalList[0].data)

        socketRef.current.on(`event/${organization}/${branchId}`, function (data) {

          setEvents((historialPrevio) => [
            ...historialPrevio,
            data
          ])

          try {
            audioRef.current.play()
          } catch (error) {
            console.log("Error al reproducir el audio: " + error)
          }

        })

        socketRef.current.on(`eventupdate/${organization}/${branchId}`, function (data) {

          let eventUpdate = data;
          if (eventUpdate !== undefined && eventUpdate !== '') {
            setEvents((prevEvents) =>
              prevEvents.filter(eventObject => eventObject._id !== eventUpdate)
            )
          }

        })
      } else {
        var listSubNetworks = []
        for (let i = 0; i < networkSubNetworks.length; i++) {
          listSubNetworks.push({ _id: networkSubNetworks[i]._id, name: networkSubNetworks[i].name })
        }
        setSubNetworks(listSubNetworks)
        setSeeAllMember(true)
      }
    }
  }

  // Metodo para agregar subredes para que seleccione el usuario solo member_branch
  const handleChangeSubNetworks = async (subNetworkId) => {

    if (subNetworkId != "") {

      var eventTotalList = []
      setSubNetwork(subNetworkId)
      var socketConnected = false

      // 1. Consulta de todos los eventos de la subred
      var existEventOrganizacionList = []
      existEventOrganizacionList = await mutateAsyncEventOrganizationSubNetworkList({ organizationId: organization, branchId: branch, subNetworkId: subNetworkId })
      eventTotalList.push(existEventOrganizacionList)
      setEvents(eventTotalList)

      socketRef.current.on(`eventmemberbranch/${organization}/${branch}/${subNetworkId}`, function (data) {

        setEvents((historialPrevio) => [
          ...historialPrevio,
          data
        ])

        socketConnected = true
        try {
          audioRef.current.play()
        } catch (error) {
          console.log("Error al reproducir el audio: " + error)
        }

      })

      if (socketConnected == false) {
        setEvents(eventTotalList[0].data)
      }

    } else {
      setEvents([])
      setSubNetwork('')
    }
  }

  // Metodo para obtener el rol por medio de la organizacion
  function getRoleBranch(organizationId) {

    for (let i = 0; i < organizationBranches.length; i++) {
      if (organizationBranches[i].organization_id == organizationId) {

        if (organizationBranches[i].isAdmin) {
          isAdmin = true
        }

        if (organizationBranches[i].role_type == 'ADMIN_ORGANIZATION' || organizationBranches[i].role_type == 'MONITOR_ORGANIZATION') {
          seeAllOrganization = true
        }

        if (organizationBranches[i].role_type == 'ADMIN_BRANCH' || organizationBranches[i].role_type == 'MONITOR_BRANCH') {
          seeAllBranch = true
        }

        if (organizationBranches[i].role_type == 'MEMBER_BRANCH') {
          memberBranch = true
        }
      }
    }
  }

  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex  flex-column text-center'>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='fw-light text-muted'>
              Organización - Listado de Eventos de Ayuda
            </h3>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <label htmlFor="organization"><b>Organización:</b></label>
          <select onChange={(event) => handleChangeOrganizations(event.target.value)}
            id='organization' name='organization' className='form-control' value={organization}>
            <option value="" >Seleccione una opción</option>
            {organizations && organizations.map((organization) => (
              <option key={organization._id} value={organization._id} >{organization.name}</option>
            ))}
          </select>
        </div>
        <div className='col-md-6'>
          <label htmlFor="branch"><b>Sucursal:</b></label>
          <select onChange={(event) => handleChangeBranches(event.target.value)} value={branch}
            id='branch' name='branch' className='form-control' >
            <option value="" >Seleccione una opción</option>
            {branches && branches.map((branch) => (
              <option key={branch._id} value={branch._id} >{branch.name}</option>
            ))}
          </select>
        </div>
      </div>

      <br></br>
      {seeAllMember == true ? (
        <div><div className='row'>
          <div><div className='col-md-6'>
            <label htmlFor="subNetwork"><b>Redes:</b></label>
            <select onChange={(event) => handleChangeSubNetworks(event.target.value)}
              id='subNetwork' name='subNetwork' className='form-control' value={subNetwork}>
              <option value="" >Seleccione una opción</option>
              {subNetworks && subNetworks.map((subNetwork) => (
                <option key={subNetwork._id} value={subNetwork._id} >{subNetwork.name}</option>
              ))}
            </select>
          </div>
            <div className='col-md-6'>

            </div>
          </div></div><br></br></div>) : ''}
      <DatatableWrapper body={events} headers={headers} paginationOptionsProps={{
        initialState: {
          rowsPerPage: 15,
          options: [5, 10, 15, 20]
        }
      }} >
        <Row className="mb-4">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter placeholder={'Ingrese información del evento a encontrar'} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions labels={{ beforeSelect: `Filas por página / Eventos encontrados ${events.length}` }} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
          </Col>
        </Row>
        <hr></hr>
        <Table>
          <TableHeader />
          <TableBody>
            {(rows) =>
              rows.length === 0 ? (
                <EmptyTablePlaceholder noResultsLabel={'Eventos no encontrados'} />
              ) : (
                rows.map((rowData, rowIdx) => (
                  <tr key={rowIdx} className='text-center'>
                    <td>{rowData.name}</td>
                    <td>{moment(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{rowData.user.name} {rowData.user.lastname}</td>
                    <td>{rowData.state}</td>
                    <td>{rowData.branch.name}</td>
                    <td>{rowData.service.name}</td>
                    <td>{rowData.organization.name}</td>
                    <td>
                      <div className='btn-group'>

                        <button
                          className='btn btn-primary btn-sm rounded-pill'
                          onClick={() => editHandler(rowData)}
                          data-bs-toggle='modal'
                          data-bs-target='#eventModal'
                        >
                          &nbsp;&nbsp;<FaPenAlt /> &nbsp; Atender&nbsp;&nbsp;
                        </button>

                      </div>
                    </td>
                  </tr>

                )
                )
              )
            }
          </TableBody>
        </Table>
      </DatatableWrapper>
    </div>
  )
}

export default ViewEvents
