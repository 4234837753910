import dynamicAPI from './dynamicAPI'
import { useMutation, useQueryClient } from 'react-query'

const url = '/api/auth/reports'
const queryKey = 'reports'

export default function useReporHook() {

  const queryClient = useQueryClient()
  
  const postReport = useMutation(
    async (obj) => await dynamicAPI('post', `${url}` , obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  return { postReport }
}
