import { Spinner, Message } from '..'
import useLicensesHook from '../../api/licenses'
import React, { useState, useEffect } from "react"
import { AiOutlineFileDone } from "react-icons/ai"
import { useLocation  } from 'react-router-dom'
import useIndividualLicensesHook from '../../api/individual-licenses'

const FormIndividualLicenses = ({
  id,
  individualLicenseObject,
  edit,
  formCleanHandler,
  isLoading,
  isError,
  handleSubmit,
  submitHandler,
  error,
}) => {

  const { getLicenses } = useLicensesHook({ })
  const { updateIndividualLicense, postIndividualLicense } = useIndividualLicensesHook({page:1, q:'' })
  const { mutateAsync: mutateAsyncUpdateIndividualLicense } = updateIndividualLicense
  const { mutateAsync: mutateAsyncPostIndividualLicense } = postIndividualLicense
  const { data } = getLicenses

  // Inicio - OrganizationLicense
  const [license, setLicense] = useState('')
  const [validSince, setValidSince] = useState('')
  const [validUntil, setValidUntil] = useState('')
  const [cost, setCost] = useState(0)
  const [maxNumberBranches, setMaxNumberBranches] = useState(0)
  const [maxNumberMembersXBranches, setMaxNumberMembersXBranches] = useState(0)
  const [maxNumberNetworksXMember, setMaxNumberNetworksXMember] = useState(0)
  const [maxNumberUsersXNetwork, setMaxNumberUsersXNetwork] = useState(0)
  const [maxNumberDevicesXMember, setMaxNumberDevicesXMember] = useState(0)
  const [active, setActive] = useState(false)
  var countEditIndividualLicense = 0

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const individualId = searchParams.get('individual_id')

  console.log(individualId)
  const addIndividualLicense = async ()  => {

   
  
    const individualLicenseAdd = await mutateAsyncPostIndividualLicense({ 
          _id: id,
          individualId: individualId, 
          licenseId: license,
          validSince: validSince,
          validUntil: validUntil, 
          cost: cost,
          maxNumberBranches: maxNumberBranches,
          maxNumberMembersXBranches: maxNumberMembersXBranches,
          maxNumberNetworksXMember: maxNumberNetworksXMember,
          maxNumberUsersXNetwork: maxNumberUsersXNetwork,
          maxNumberDevicesXMember: maxNumberDevicesXMember,
          active: active
        })

      console.log(individualLicenseAdd)
      // Seteamos las variables  
      setLicense('')
      setValidSince('')
      setValidUntil('')
      setCost(0)
      setMaxNumberBranches(0)
      setMaxNumberMembersXBranches(0)
      setMaxNumberNetworksXMember(0)
      setMaxNumberDevicesXMember(0)
      setActive(false)
  }


  useEffect(() => {
  
    if(individualLicenseObject != undefined){
      countEditIndividualLicense++
      if(countEditIndividualLicense ==1){
        setLicense(individualLicenseObject.license._id)
        setValidSince(individualLicenseObject.validSince)
        setValidUntil(individualLicenseObject.validUntil) 
        setCost(individualLicenseObject.cost)
        setMaxNumberBranches(individualLicenseObject.maxNumberBranches)
        setMaxNumberMembersXBranches(individualLicenseObject.maxNumberMembersXBranches)
        setMaxNumberNetworksXMember(individualLicenseObject.maxNumberNetworksXMember)
        setMaxNumberUsersXNetwork(individualLicenseObject.maxNumberUsersXNetwork)
        setMaxNumberDevicesXMember(individualLicenseObject.maxNumberDevicesXMember)
        setActive(individualLicenseObject.active)
      }
    }
  }, [individualLicenseObject])

  const editIndividualLicense = async () => {

    const organizationLicenseEdit = await mutateAsyncUpdateIndividualLicense({  
      licenseId: license,
      validSince: validSince,
      validUntil: validUntil, 
      cost: cost,
      maxNumberBranches: maxNumberBranches,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      maxNumberNetworksXMember: maxNumberNetworksXMember,
      maxNumberUsersXNetwork: maxNumberUsersXNetwork,
      maxNumberDevicesXMember: maxNumberDevicesXMember,
      active: active
    })
    console.log(organizationLicenseEdit)
    setLicense('')
    setValidSince('')
    setValidUntil('') 
    setCost(0)
    setMaxNumberBranches(0)
    setMaxNumberMembersXBranches(0)
    setMaxNumberNetworksXMember(0)
    setMaxNumberUsersXNetwork(0)
    setMaxNumberDevicesXMember(0)
    setActive(false)
  }


  return (
    <div
      className='modal fade'
      id='organizationLicenseModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='organizationLicenseModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-pequeno'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='organizationLicenseModalLabel'>
              {edit ? 'Editar Licencia por Organización' : 'Agregar Licencia a la Organización'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>

                <div className='row'>
                  <div className='col-md-6'>
                    <label htmlFor="license"><b>Licencia:</b></label>
                    <select onChange={(event) => setLicense(event.target.value)} 
                          name='license' className='form-control' value={license}> 
                          <option key="" >Seleccione una opción</option>
                          {data && data.data.map((license) => (
                            <option key ={license._id}  >{license.name}</option>
                          ))}
                    </select>
                  </div>  
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Desde:</b></label>
                    <input
                      type='date'
                      name='validSince'
                      value={validSince}
                      onChange={(event) => setValidSince(event.target.value)}
                      className='form-control'                
                    /> 
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Hasta:</b></label>
                      <input
                        type='date'
                        name='validUntil'
                        value={validUntil}
                        onChange={(event) => setValidUntil(event.target.value)}
                        className='form-control'                
                      /> 
                    </div>
                  <div className='col-md-6'>
                      <label htmlFor="cost"><b>Costo:</b></label>
                      <input
                        type='number'
                        name='cost'
                        value={cost}
                        onChange={(event) => setCost(event.target.value)}
                        className='form-control'                
                      /> 
                  </div>
                  <div className='col-md-6'>
                      <label htmlFor="maxNumberBranches"><b>Nª Máximo de Sucursales:</b></label>
                      <input
                        type='number'
                        name='maxNumberBranches'
                        value={maxNumberBranches}
                        onChange={(event) => setMaxNumberBranches(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="maxNumberMembersXBranches"><b>Nª Máximo Miembros Por Sucursal:</b></label>
                    <input
                        type='number'
                        name='maxNumberMembersXBranches'
                        value={maxNumberMembersXBranches}
                        onChange={(event) => setMaxNumberMembersXBranches(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className='col-md-6'>
                  <label htmlFor="maxNumberNetworksXMember"><b>Nª Máximo Redes Por Miembro:</b></label>
                    <input
                        type='number'
                        name='maxNumberNetworksXMember'
                        value={maxNumberNetworksXMember}
                        onChange={(event) => setMaxNumberNetworksXMember(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="maxNumberUsersXNetwork"><b>Nª Máximo de Usuarios Por Red:</b></label>
                    <input
                        type='number'
                        name='maxNumberUsersXNetwork'
                        value={maxNumberUsersXNetwork}
                        onChange={(event) => setMaxNumberUsersXNetwork(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="maxNumberDevicesXMember"><b>Nª Máximo de Dispositivos Por Miembro:</b></label>
                    <input
                        type='number'
                        name='maxNumberDevicesXMember'
                        value={maxNumberDevicesXMember}
                        onChange={(event) => setMaxNumberDevicesXMember(event.target.value)}
                        className='form-control'                
                      />
                  </div>
                  <div className="col-md-6">    
                    <label htmlFor="active"><b>Estado:</b></label>
                    <div className='form-check form-switch form-control'>
                      <input className='form-check-input'
                        type ='checkbox'
                        id ='active'
                        name ='active'
                        value ={active}
                        onChange={(event) => setActive(event.target.checked)}
                        
                      />
                    <label className='form-check-label' htmlFor='active'>
                      Activo
                    </label>
                  </div>
                  
                </div>             
              </div>
              <div className='row'>&nbsp;</div>
              <div className='row'>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <button type='button' onClick={edit ? editIndividualLicense:addIndividualLicense}  className='btn btn-primary form-control'  >
                      <AiOutlineFileDone /> &nbsp; {edit ? 'Actualizar' : 'Agregar '}
                  </button>    
                </div>
                <div className="col-md-4"></div>
              </div>

              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormIndividualLicenses
