import { useEffect, useState } from 'react'
import { Message } from '../../components'
import useUploadHook from '../../api/upload'
import useProfilesHook from '../../api/profiles'
import { Helmet } from 'react-helmet'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { AiOutlineUser, AiOutlineAppstoreAdd, AiOutlineKey, AiOutlineClear, AiOutlineCamera, AiFillLock } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { FiEyeOff, FiEye } from "react-icons/fi"



const Profile = () => {

  // Inicio carga informacion perfil usuario
  var { getProfile, postProfile } = useProfilesHook({})
  var { data, isLoading, isError, error } = getProfile
  var { isSuccess, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, mutateAsync } = postProfile
  var [message, setMessage] = useState('')
  var [phoneNumber, setPhoneNumber] = useState('')
  var [sex, setSex] = useState('')
  var [birthday, setBirthday] = useState('')
  var [typeIdentification, setTypeIdentification] = useState('')
  var [identification, setIdentification] = useState('')
  var [accountValidationPhoneToken, setAccountValidationPhoneToken] = useState('')
  var [accountValidationEmailToken, setAccountValidationEmailToken] = useState('')
  var [email, setEmail] = useState('')
  const [type, setType] = useState('password')
  const [icon, setIcon] = useState(FiEyeOff)
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(FiEye);
      setType('text')
    } else {
      setIcon(FiEyeOff)
      setType('password')
    }
  }

  const [typeConfirm, setTypeConfirm] = useState('password')
  const [iconConfirm, setIconConfirm] = useState(FiEyeOff)
  const handleToggleConfirm = () => {
    if (typeConfirm === 'password') {
      setIconConfirm(FiEye);
      setTypeConfirm('text')
    } else {
      setIconConfirm(FiEyeOff)
      setTypeConfirm('password')
    }
  }

  useEffect(() => {

    if (data != undefined) {

      if (data.phone != undefined) {
        setPhoneNumber(data.phone)
      } else {
        setPhoneNumber('')
      }

      if (data.sex != undefined) {
        setSex(data.sex)
      } else {
        setSex('')
      }

      if (data.birthday != undefined) {
        setBirthday(data.birthday.substring(0, 10))
      } else {
        setBirthday('')
      }

      if (data.typeIdentification != undefined) {
        setTypeIdentification(data.typeIdentification)
      } else {
        setTypeIdentification('')
      }

      if (data.identification != undefined) {
        setIdentification(data.identification)
      } else {
        setIdentification('')
      }

      if (data.email != undefined) {
        setEmail(data.email)
      } else {
        setEmail('')
      }

      if (data.accountValidationPhoneToken != undefined) {
        setAccountValidationPhoneToken(data.accountValidationPhoneToken)
      } else {
        setAccountValidationPhoneToken(undefined)
      }

      if (data.accountValidationEmailToken != undefined) {
        setAccountValidationEmailToken(data.accountValidationEmailToken)
      } else {
        setAccountValidationEmailToken(undefined)
      }

    }
  }, [data])

  // Metodo para Actualizar foto de perfil
  const { postUpload } = useUploadHook()
  var { isError: isErrorUpload, error: errorUpload, mutateAsync: mutateAsyncUpload } = postUpload
  const handleFileChange = (e) => {
    document.getElementById("upload-image-profile").style.display = "inline-block";
    if (e.target.files) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = async () => {
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        const imageUpload = await mutateAsyncUpload({ type: 'image-user', formData, id: data._id })
        data.image = imageUpload.image
        document.getElementById("upload-image-profile").style.display = 'none'
      }
    }
  }



  // Metodo para la actualizacion de datos personales
  const updatePersonalData = async () => {

    const retornoUpdatePersonalData = await mutateAsync({
      sex: sex,
      birthday: birthday,
      typeIdentification: typeIdentification,
      identification: identification,
      type: 'update-personal-data',
    })
    if (retornoUpdatePersonalData != undefined) {
      setMessage(retornoUpdatePersonalData.message)
    }

  }

  // Metodo para la actualizacion del telefono del usuario
  const updateUserPhone = async () => {

    const retornoUpdateUserPhone = await mutateAsync({
      phone: phoneNumber,
      type: 'update-user-phone',
    })
    if (retornoUpdateUserPhone != undefined) {
      setMessage(retornoUpdateUserPhone.message)
    }

  }

  // Metodo para la actualizacion del email del usuario
  const updateUserEmail = async () => {

    const retornoUpdateUserEmail = await mutateAsync({
      email: email,
      type: 'update-user-email',
    })
    console.log(retornoUpdateUserEmail)
    if (retornoUpdateUserEmail != undefined) {
      setMessage(retornoUpdateUserEmail.message)
    }

  }

  // Inicio Cambio de Clave
  const [inputChangePassword, setInputChangePassword] = useState({ password: '', confirmPassword: '' })
  const [errorChangePassword, setErrorChangePassword] = useState({ password: '', confirmPassword: '' })

  const onInputChange = e => {
    const { name, value } = e.target;
    setInputChangePassword(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setErrorChangePassword(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {

        case "password":
          if (!value) {
            stateObj[name] = "Ingrese la Contraseña.";
          } else if (inputChangePassword.confirmPassword && value !== inputChangePassword.confirmPassword) {
            stateObj["confirmPassword"] = "Contraseña y Confirmar Contraseña no coinciden.";
          } else {
            stateObj["confirmPassword"] = inputChangePassword.confirmPassword ? "" : errorChangePassword.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Ingrese la Confirmación de la Contraseña.";
          } else if (inputChangePassword.password && value !== inputChangePassword.password) {
            stateObj[name] = "Contraseña y Confirmar Contraseña no coinciden.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    })
  }

  const changePassword = async () => {

    const retornoUpdatePassword = await mutateAsync({
      password: inputChangePassword.password,
      type: 'update-password',
    })
    if (retornoUpdatePassword != undefined) {
      setMessage(retornoUpdatePassword.message)
    }
  }

  const cleanPassword = () => {
    setInputChangePassword({ password: '', confirmPassword: '' })
    setErrorChangePassword({ password: '', confirmPassword: '' })
  }

  // Fin Cambio de Clave




  return (
    <>
      <Helmet>
        <title>Perfil del Usuario</title>
        <meta property='og:title' content='Perfil' key='title' />
      </Helmet>
      <div id="containerNetwork" name="containerNetwork" className='table-responsive bg-light p-3 mt-2'>
        <div className='d-flex flex-column text-center'>
          <h3 className='fw-light text-muted'>Perfil del Usuario</h3>
          {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
          {isErrorUpload && <Message variant='danger'>{errorUpload}</Message>}
          {isError && <Message variant='danger'>{error}</Message>}
          {isSuccess && (
            <Message variant='success'>{message}</Message>
          )}


          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineUser /> Datos Generales</a>
            </li>

            <li className="nav-item" role="presentation">
              <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiFillLock /> Cambio Contraseña</a>
            </li>

          </ul>
          <div className="tab-content pt-3" id="tab-content">
            <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">

              <div className='row'>
                <div className='col-md-6'>
                  {data && data.image && (
                    <div className='d-flex justify-content-center'>
                      <img
                        src={data && data.image}
                        alt=''
                        className='rounded-circle'
                        width='150'
                        height='150'
                      />
                    </div>
                  )}
                  <div className='row'>
                    <label htmlFor="file" className="sr-only">
                      &nbsp;
                    </label>
                    <div className='row'>
                      <center>
                        &nbsp;&nbsp;
                        <label htmlFor="image" className="btn btn-primary btn-block btn-outlined">

                          <span id="upload-image-profile" className='spinner-border spinner-border-sm' style={{ display: 'none' }} />
                          &nbsp;<AiOutlineCamera />&nbsp;
                          Actualice su foto de perfil
                        </label>
                        <input id="image" type="file" accept="image/*" onChange={handleFileChange} className='form-control' style={{ display: 'none' }} />
                      </center>
                    </div>

                  </div>
                  <hr></hr>
                  <div className='row text-center'>
                    <label><b>Nombre Completo:</b> &nbsp; &nbsp; {data && data.name} {data && data.lastname}</label>
                  </div>

                </div>
                <div className='col-md-6'>

                  <div className='row'>

                    <div className='row'>
                      <div className="col-md-3">
                        <label htmlFor="phone" className='form-control' style={{ border: '1px transparent' }}><b>Teléfono:</b></label>
                      </div>
                      <div className="col-md-4">

                        <PhoneInput
                          name="phone"
                          placeholder="Ingrese un número telefónico"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          className='form-control'
                        />

                      </div>
                      <div className="col-md-2">
                        {accountValidationPhoneToken == '' ? (
                          <div><label className='form-control' style={{ border: '1px transparent' }}><FaCheckCircle className='text-success' />&nbsp;Validado</label></div>
                        ) : (
                          <div><label className='form-control' style={{ border: '1px transparent' }}><FaTimesCircle className='text-danger' />&nbsp;Sin Validar</label></div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <button type='button' onClick={() => updateUserPhone()} className='btn btn-success form-control'>
                          Actualizar
                        </button>
                      </div>
                    </div>


                  </div>
                  <br></br>
                  <div className='row'>

                    <div className='row'>
                      <div className="col-md-3">
                        <label htmlFor="email" className='form-control' style={{ border: '1px transparent' }}><b>Correo Electrónico:</b></label>
                      </div>
                      <div className="col-md-4">
                        <input
                          type='text'
                          name='email'
                          value={email || ''}
                          onChange={(event) => setEmail(event.target.value)}
                          className='form-control'
                        />
                      </div>
                      <div className="col-md-2">
                        {accountValidationEmailToken == '' ? (
                          <div><label className='form-control' style={{ border: '1px transparent' }}><FaCheckCircle className='text-success' />&nbsp;Validado</label></div>
                        ) : (
                          <div><label className='form-control' style={{ border: '1px transparent' }}><FaTimesCircle className='text-danger' />&nbsp;Sin Validar</label></div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <button type='button' onClick={() => updateUserEmail()} className='btn btn-success form-control'>
                          Actualizar
                        </button>
                      </div>
                    </div>


                  </div>
                  <br></br>
                  <div className='row'>
                    <div className="col-md-6">
                      <label htmlFor="sex"><b>Sexo:</b></label>
                      <select onChange={(event) => setSex(event.target.value)}
                        name='sex' className='form-control' value={sex}>
                        <option key="" >Seleccione una opción</option>
                        <option key="Masculino" >Masculino</option>
                        <option key="Femenino" >Femenino</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="birthday"><b>F. Nacimiento:</b></label>
                      <input
                        type='date'
                        name='birthday'
                        value={birthday || ''}
                        onChange={(event) => setBirthday(event.target.value)}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-6">
                      <label htmlFor="typeIdentification"><b>Tipo Identificación:</b></label>
                      <select onChange={(event) => setTypeIdentification(event.target.value)}
                        name='typeIdentification' className='form-control' value={typeIdentification}>
                        <option key="" >Seleccione una opción</option>
                        <option key="RUC" >RUC</option>
                        <option key="Cedula" >Cedula</option>
                        <option key="Pasaporte" >Pasaporte</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="identification"><b>Identificación:</b></label>
                      <input
                        type='text'
                        name='identification'
                        value={identification || ''}
                        onChange={(event) => setIdentification(event.target.value)}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className='row'>
                    <div className="col-md-1">

                    </div>
                    <div className="col-md-10">
                      <button type='button' onClick={() => updatePersonalData()} className='btn btn-primary form-control' disabled={isLoadingPost}>
                        {isLoadingPost ? (
                          <span className='spinner-border spinner-border-sm' />
                        ) : (
                          <div><AiOutlineAppstoreAdd />&nbsp; Actualizar Datos Personales</div>
                        )}
                      </button>
                    </div>
                    <div className="col-md-1">

                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">
              <div className="row">
                <div className='col-md-3'></div>
                <div className='col-md-6'>

                  <div className="card">
                    <div className="card-header">
                      <div className="alert alert-primary" role="alert">
                        Ingrese los valores requeridos para realizar el proceso de cambio de contraseña.<br></br>
                        Mínimo 6 caracteres, al menos 1 letra minúscula, 1 letra mayúscula y 1 número.<br></br>
                        <b>No se permiten caracteres especiales cómo: +,-,$,*,/,_,etc</b>

                      </div>
                    </div>
                    <div className="card-body">

                      <div className="row">
                        <div className='col-md-6'>
                          <div className='mb-3 flex'>
                            <input
                              type={type}
                              name="password"
                              placeholder='Ingrese la Contraseña'
                              value={inputChangePassword.password}
                              onChange={onInputChange}
                              onBlur={validateInput} className='form-control' >
                            </input>
                            <span className="flex justify-around items-center" onClick={handleToggle}>
                              <p className="input-password">{icon}</p>
                            </span>
                          </div>
                          <center>{errorChangePassword.password && <span className='err' style={({ color: 'red' })}>{errorChangePassword.password}</span>}</center>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3 flex'>
                            <input
                              type={typeConfirm}
                              name="confirmPassword"
                              placeholder='Confirme la Contraseña'
                              value={inputChangePassword.confirmPassword}
                              onChange={onInputChange}
                              onBlur={validateInput} className='form-control'></input>
                            <span className="flex justify-around items-center" onClick={handleToggleConfirm}>
                              <p className="input-password">{iconConfirm}</p>
                            </span>
                          </div>
                          <center>{errorChangePassword.confirmPassword && <span className='err' style={({ color: 'red' })}>{errorChangePassword.confirmPassword}</span>}</center>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className='col-md-6'>
                          <button type='button' className='btn btn-primary form-control' disabled={isLoading} onClick={changePassword}>
                            {isLoading ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : (
                              ''
                            )} <AiOutlineKey /> &nbsp; Cambiar
                          </button> &nbsp;
                        </div>
                        <div className='col-md-6'>
                          <button type='button' className='btn btn-primary form-control' onClick={() => cleanPassword()}>
                            <AiOutlineClear /> &nbsp; Limpiar
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3'></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
