import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useLicensesHook from '../../../api/licenses'
import {
  Spinner,
  Pagination,
  Message,
} from '../../../components'
import FormLicenses from '../../../components/licenses/FormLicenses'
import ViewLicenses from '../../../components/licenses/ViewLicenses'

const Licenses = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')

  const { getLicenses, postLicense, updateLicense } =
    useLicensesHook({
      page,
      q,
    })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  const { data, isLoading, isError, error, refetch } = getLicenses

  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateLicense


  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postLicense

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) formCleanHandler()
  }, [isSuccessPost, isSuccessUpdate])

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          name: data.name,
          comercialName: data.comercialName,
          cost: data.cost,
          type: data.type,
          timeValidity: data.timeValidity,
          timeValidityUnit: data.timeValidityUnit,
          active: data.active,
        })
      : mutateAsyncPost(data)
  }

  const editHandler = (service) => {
    setId(service._id)
    setEdit(true)
    setValue('name', service.name)
    setValue('comercialName', service.comercialName)
    setValue('cost', service.cost)
    setValue('type', service.type)
    setValue('timeValidity', service.timeValidity)
    setValue('timeValidityUnit', service.timeValidityUnit)
    setValue('active', service.active)
  }

  return (
    <>
      <Helmet>
        <title>Licencias</title>
        <meta property='og:title' content='Licencias' key='title' />
      </Helmet>
      {isSuccessUpdate && (
        <Message variant='success'>
         La Licencia se ha actualizado correctamente.
        </Message>
      )}
      {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {isSuccessPost && (
        <Message variant='success'>
          La Licencia se ha creado correctamente.
        </Message>
      )}
      {isErrorPost && <Message variant='danger'>{errorPost}</Message>}

      <FormLicenses
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingPost={isLoadingPost}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
      />

     

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <ViewLicenses
          data={data}
          editHandler={editHandler}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}
       <div className='ms-auto '>
        <center><Pagination data={data} setPage={setPage} /></center>
      </div>
    </>
  )
}

export default Licenses
