import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useLocation  } from 'react-router-dom'

const url = '/api/auth/branch-licenses'

const queryKey = 'branch-licenses' 

export default function useBranchLicensesHook(props) {
  const { page = 1, id, q = '', limit = 25 } = props
  const queryClient = useQueryClient()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const branch_id = searchParams.get('branch_id')

  const getBranchLicenses = branch_id!=undefined ? useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}&branch_id=${branch_id}`, {}),
    { retry: 0 }
  ):''

  const getBranchLicenseById = useQuery(
    queryKey,
    async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  )

  const updateBranchLicense =  useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const deleteBranchLicense = useMutation(
    async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postBranchLicense = useMutation(
    async (obj) => await dynamicAPI('post', url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  return {
    getBranchLicenses,
    updateBranchLicense,
    deleteBranchLicense,
    postBranchLicense,
    getBranchLicenseById,
  }
}
