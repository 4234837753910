import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'

const ViewNetworks = ({
  data,
  editHandler,
  deleteHandler,
  formCleanHandler,
  isLoadingDelete,
  isSuperAdmin
}) => {

  const headers = [
    { title: 'Nombre', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Usuarios', prop: 'members', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Activo', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]


  return (
    <div id="containerNetwork" name="containerNetwork" className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        {isSuperAdmin ? (
          <button
            className='btn btn-outline-primary btn-sm shadow my-2'
            data-bs-toggle='modal'
            onClick={formCleanHandler}
            data-bs-target='#networkModal'
            data-bs-placement="top" title="Clic para Agregar una Red para Abonados Secundarios"
          >
            Agregar una Red
          </button>) : ''}
      </div>

      {data != undefined ?(
      <DatatableWrapper body={data.data} headers={headers} paginationOptionsProps={{
        initialState: {
          rowsPerPage: 15,
          options: [5, 10, 15, 20]
        }
      }} >
        <Row className="mb-4">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter placeholder={'Ingrese información del Redes a encontrar'} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions labels={{ beforeSelect: `Filas por página / Redes encontradas ${data.data.length}` }} />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
          </Col>
        </Row>
        <hr></hr>
        <Table>
          <TableHeader />
          <TableBody>
            {(rows) =>
              rows.length === 0 ? (
                <EmptyTablePlaceholder noResultsLabel={'Redes no encontradas'} />
              ) : (
                rows.map((rowData, rowIdx) => (
                  <tr key={rowIdx} className='text-center'>
                    <td>{rowData.name} </td>
                    <td>{rowData.members == undefined ? 0 : rowData.members.length} </td>
                    <td>
                      {rowData.active ? (
                        <FaCheckCircle className='text-success' />
                      ) : (
                        <FaTimesCircle className='text-danger' />
                      )}

                    </td>

                    <td>
                      <div className='btn-group'>

                       

                        <button
                      id={`${rowData._id}_edit_button`}
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(rowData)}
                      data-bs-toggle='modal'
                      data-bs-target='#networkModal'
                      data-bs-placement="top" title="Clic para Editar Red de Abonado Secundario"
                    >
                      <FaPenAlt />
                    </button>
                    

                    <button
                      className='btn btn-danger btn-sm ms-1 rounded-pill '
                      onClick={() => { if (window.confirm('Desea eliminar la Red del Abonado Secundario? Tenga cuidado se eliminarán todos los usuarios')) deleteHandler(rowData.network_id + '-' + rowData._id) }}
                      disabled={isLoadingDelete}
                      data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Eliminar Red de Abonado Secundario"
                    >

                      <FaTrash />

                    </button>



                      </div>
                    </td>
                  </tr>

                )
                )
              )
            }
          </TableBody>
        </Table>
      </DatatableWrapper>):''}


    </div>
  )
}

export default ViewNetworks
