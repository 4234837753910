import { Helmet } from 'react-helmet';

const Terminos = () => {
    return (
        <>
            <Helmet>
                <title>Aviso de privacidad</title>
                <meta property='og:title' content='Alerta Global - Constecoin' key='title' />
            </Helmet>
            <div style={{
                width: '100%',
                height: 'auto',
                background: '#B65353',
                alignContent: 'center',
                textAlign: 'center'
            }}>
                <h1 style={{
                    textTransform: 'uppercase',
                    color: 'white',
                    animation: 'slideFromLeft 1s ease forwards'
                }}>política de privacidad de alerta global</h1>
            </div>
            <div style={{ marginTop: '15px', width: '100%', height: 'auto', background: 'white', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '75%', textAlign: 'justify', animation: 'slideFromLeft 1s ease forwards' }}>
                    <div className='subtitle-politicas'>
                        <h4>Introducción</h4>
                    </div>
                    <p>	Alerta Global (&quot;Alerta Global&quot;, &quot;nosotros&quot;, &quot;nos&quot; o &quot;nuestro&quot;)
                        se compromete a proteger la privacidad de sus usuarios. Este Aviso de Privacidad (&quot;Aviso&quot;) describe
                        cómo recolectamos, almacenamos y utilizamos su información personal cuando utiliza nuestra
                        aplicación móvil Alerta Global (la &quot;Aplicación&quot;)</p>

                    <div className='subtitle-politicas'>
                        <h4>Recopilación de la información</h4>
                    </div>
                    <p>Recopilamos la siguiente información personal cuando utiliza la Aplicación:</p>
                    <ol>
                        <li style={{ listStyleType: "disc" }}>Información personal: Recopilamos,
                            sus nombres completos, número de identificación personal. </li>
                        <li style={{ listStyleType: "disc" }}>Información de registro: Cuando crea
                            una cuenta en la Aplicación, recopilamos, ubicación, dirección de correo
                            electrónico, número de teléfono y contraseña.</li>
                        <li style={{ listStyleType: "disc" }}>Información de ubicación:
                            Cuando utiliza la Aplicación para enviar una alerta,
                            recopilamos su ubicación en el momento de producirse la alerta. </li>
                        <li style={{ listStyleType: "disc" }}>Otra información: También
                            podemos recopilar otra información sobre usted, como su edad y sexo,
                            si decide proporcionarla.</li>
                    </ol>

                    <div className='subtitle-politicas'>
                        <h4>Uso de la información personal</h4>
                    </div>
                    <p>Utilizamos su información personal para los siguientes fines:</p>
                    <ol>
                        <li style={{ listStyleType: "disc" }}>Proporcionar los servicios de la
                            Aplicación: Utilizamos su información personal para proporcionarle
                            los servicios de la Aplicación, como enviarle alertas a los servicios de
                            emergencia y a su red de contactos.</li>
                        <li style={{ listStyleType: "disc" }}>Mejorar la Aplicación: Utilizamos
                            su información personal para mejorar la Aplicación y nuestros servicios.</li>
                        <li style={{ listStyleType: "disc" }}>Proteger a nuestros usuarios:
                            Utilizamos su información personal para proteger a nuestros usuarios de
                            daños y actividades perjudiciales. </li>
                        <li style={{ listStyleType: "disc" }}>Comunicarnos con usted:
                            Podemos utilizar su información personal para comunicarnos
                            con usted sobre su cuenta, la Aplicación o nuestros servicios.</li>
                    </ol>

                    <div className='subtitle-politicas'>
                        <h4>Divulgación de la información personal</h4>
                    </div>
                    <p>No compartimos su información personal con terceros, excepto en los siguientes casos:</p>
                    <ol>
                        <li style={{ listStyleType: "disc" }}>Para cumplir con la ley:
                            Podemos compartir su información personal si estamos obligados
                            a hacerlo por ley o por una orden judicial.</li>
                        <li style={{ listStyleType: "disc" }}>•	Para proteger nuestros derechos:
                            Podemos compartir su información personal si creemos que es necesario
                            para proteger nuestros derechos, propiedad o seguridad.</li>
                    </ol>

                    <div className='subtitle-politicas'>
                        <h4>¿Cómo garantizamos la seguridad de su información?</h4>
                    </div>
                    <p>Implementamos tanto procesos como procedimientos organizativos y tecnológicos
                        para resguardar su información personal. No obstante, es relevante señalar que
                        no podemos asegurar una protección absoluta en las transmisiones electrónicas a
                        través de Internet ni en las tecnologías de almacenamiento de datos, debido a la
                        inexistencia de una garantía del 100% de seguridad.
                        Por ende, no podemos asumir un compromiso infalible ni asegurar que hackers,
                        delincuentes cibernéticos u otros terceros no autorizados no puedan eludir nuestras
                        medidas de seguridad y acceder de manera indebida, obtener, sustraer o modificar
                        su información.
                    </p>

                    <div className='subtitle-politicas'>
                        <h4>Cumplimiento de la Ley de Protección de Datos Personales de Ecuador</h4>
                    </div>
                    <p>Alerta Global cumple con los principios y las obligaciones establecidos en
                        la Ley Orgánica de Protección de Datos Personales (LOPDP) de Ecuador.
                        A continuación, se detallan algunos aspectos clave de la LOPDP que se
                        consideran en nuestras prácticas de privacidad:
                    </p>
                    <ol>
                        <li>
                            Principios:
                        </li>
                        <ol>
                            <li style={{ listStyleType: "disc" }}>
                                Legalidad: El tratamiento de datos personales en Alerta Global
                                se basa en una base legal válida, como el consentimiento expreso del
                                usuario o el cumplimiento de una obligación legal. (Art. 7 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Lealtad y transparencia: Proporcionamos información clara,
                                precisa y fácilmente accesible sobre cómo recolectamos, usamos y
                                protegemos sus datos personales. (Art. 8 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Legitimidad: Los datos personales solo se recopilan para fines
                                específicos, explícitos y legítimos, y no se utilizan de forma
                                incompatible con dichos fines. (Art. 9 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Finalidad: Los datos personales se limitan a lo que es adecuado,
                                pertinente y necesario en relación con los fines para los que se tratan.
                                (Art. 10 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Pertinencia: Los datos personales se mantienen exactos y actualizados
                                cuando es necesario. (Art. 11 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Minimización: Solo recolectamos los datos personales que son estrictamente
                                necesarios para los fines previstos. (Art. 12 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Seguridad: Implementamos medidas de seguridad técnicas y organizativas
                                adecuadas para proteger los datos personales contra la destrucción, pérdida,
                                alteración, acceso no autorizado o divulgación. (Art. 13 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Confidencialidad: Los datos personales se tratan de forma confidencial y
                                solo son accesibles a las personas autorizadas. (Art. 14 LOPDP)
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                Conservación: Los datos personales solo se conservan durante el tiempo
                                necesario para los fines que inicialmente se recopilaron o para cumplir con
                                obligaciones legales. (Art. 15 LOPDP)
                            </li>
                        </ol>
                        <li>
                            Derechos de los usuarios:
                        </li>
                        <p>Respetamos los derechos de los Usuarios o titulares de datos establecidos en
                            la LOPDP, incluyendo:
                        </p>
                        <ol>
                            <li style={{ listStyle: "disc" }}>
                                Acceso: Los usuarios tienen derecho a acceder a sus datos personales
                                y a obtener información sobre cómo se están tratando. (Art. 18 LOPDP)
                            </li>
                            <li style={{ listStyle: "disc" }}>
                                Rectificación: Los usuarios tienen derecho a solicitar la rectificación
                                de sus datos personales si son inexactos o incompletos. (Art. 19 LOPDP)
                            </li>
                            <li style={{ listStyle: "disc" }}>
                                Supresión: Los usuarios tienen derecho a solicitar la supresión de sus
                                datos personales en determinados casos, como cuando ya no son necesarios
                                para los fines que inicialmente se recopilaron o cuando se han tratado de forma ilícita. (Art. 20 LOPDP).
                            </li>
                            <li style={{ listStyle: "disc" }}>
                                Limitación del tratamiento: Los usuarios tienen derecho a solicitar que
                                el tratamiento de sus datos personales se limite en determinadas circunstancias,
                                como cuando impugnan la exactitud de sus datos o mientras se evalúa una solicitud
                                de supresión. (Art. 21 LOPDP)
                            </li>
                        </ol>
                    </ol>

                    <div className='subtitle-politicas'>
                        <h4>¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO? </h4>
                    </div>
                    <p>En resumen: podemos utilizar cookies y otras tecnologías de seguimiento para recopilar
                        y almacenar su información.
                        Podemos utilizar cookies y tecnologías de seguimiento similares (como balizas web y píxeles)
                        para acceder o almacenar información.
                    </p>
                    <div className='subtitle-politicas'>
                        <h4>¿RECOPILAMOS INFORMACIÓN DE USUARIOS MENORES DE EDAD?</h4>
                    </div>
                    <p>En resumen: no recopilamos datos de niños menores de 11 años de edad, ni los comercializamos a sabiendas.
                        No solicitamos ni comercializamos datos de niños menores de 11 años. Al utilizar los Servicios, usted declara
                        que tiene al menos 11 años o que es el padre o tutor de un menor de edad y consiente del uso de los Servicios
                        por parte del menor.
                        Si descubrimos que hemos recopilado información personal de usuarios menores de 11 años, desactivaremos la
                        cuenta y tomaremos las medidas necesarias para eliminar rápidamente dicha información de nuestros registros.
                        Si tiene conocimiento de que hemos recopilado datos de niños menores de 11 años, por favor contáctenos info_app@constecoin.com.
                    </p>
                    <div className='subtitle-politicas'>
                        <h4>Sus opciones</h4>
                    </div>
                    <p>
                        Puede acceder a su información personal y actualizarla en la configuración de su cuenta. También puede solicitar que eliminemos su
                        información personal enviándonos un correo electrónico a info_app@constecoin.com.
                    </p>
                    <div className='subtitle-politicas'>
                        <h4>Seguridad de la información personal</h4>
                    </div>
                    <p>
                        Tomamos medidas de seguridad técnicas y organizativas para proteger su información personal contra la destrucción, pérdida,
                        alteración, acceso no autorizado o divulgación.
                    </p>
                    <div className='subtitle-politicas'>
                        <h4>Cambios en este Aviso</h4>
                    </div>
                    <p>Nos reservamos el derecho de modificar este Aviso de Privacidad en cualquier momento mediante la publicación de los
                        cambios en la Aplicación. En caso de realizar cambios significativos, le informaremos publicando el Aviso revisado
                        en la Aplicación.
                    </p>
                    <div className='subtitle-politicas'>
                        <h4>¿Preguntas o inquietudes?</h4>

                    </div>
                    <p>
                        Alerta Global se compromete a proteger su privacidad y brindarle control sobre su información personal.
                        Le recomendamos que lea atentamente este Aviso de Privacidad para comprender sus derechos y opciones.
                        Si no está de acuerdo con nuestras políticas y prácticas de privacidad, no debe utilizar nuestros servicios.
                        Si tiene alguna pregunta o inquietud sobre este Aviso de Privacidad o nuestras prácticas de privacidad,
                        no dude en contactarnos en: info_app@constecoin.com.
                        Estaremos encantados de responder a sus preguntas y ayudarlo a comprender sus derechos.
                    </p>
                </div>
            </div>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '15px', marginBottom: '15px', fontSize: '25px' }}>Gracias por usar Alerta Global.</div>
        </>
    );
}

export default Terminos;
