import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'
import moment from 'moment'

const ViewOrganizationLicenses = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  formCleanHandler,
}) => {

  const headers = [
    { title: 'Licencia', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Vigencia Desde', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Vigencia Hasta', prop: `identification`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Activo', prop: 'phone', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]

  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Listado de Contratos <sup className='fs-6'> [{data && data.total}] </sup>
        </h3>
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#branchLicenseModal'
          onClick={formCleanHandler}
          data-bs-placement="top" title="Clic para Agregar un Contrato"
        >
          Agregar un Contrato
        </button>
      </div>


      {data != undefined ? (
        <DatatableWrapper body={data.data} headers={headers} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 15,
            options: [5, 10, 15, 20]
          }
        }} >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter placeholder={'Ingrese información de la sucursal a encontrar'} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions labels={{ beforeSelect: `Filas por página / Sucursales encontradas ${data.data.length}` }} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
            </Col>
          </Row>
          <hr></hr>
          <Table>
            <TableHeader />
            <TableBody>
              {(rows) =>
                rows.length === 0 ? (
                  <EmptyTablePlaceholder noResultsLabel={'Sucursales no encontradas'} />
                ) : (
                  rows.map((rowData, rowIdx) => (
                    <tr key={rowIdx} className='text-center'>
                      <td>{rowData.license.name}</td>
                      <td>{rowData.validSince == undefined ? '' : moment(rowData.validSince).add(1, 'd').format("YYYY-MM-DD")}</td>
                      <td>{rowData.validUntil == undefined ? '' : moment(rowData.validUntil).add(1, 'd').format("YYYY-MM-DD")}</td>
                      <td>
                        {rowData.active ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}
                      </td>
                      <td>
                        <div className='btn-group'>

                          <button
                            id={`${rowData._id}_edit_button`}
                            className='btn btn-primary btn-sm rounded-pill'
                            onClick={() => editHandler(rowData)}
                            data-bs-toggle='modal'
                            data-bs-target='#branchLicenseModal'
                            data-bs-placement="top" title="Clic para Editar un Contrato"
                          >
                            <FaPenAlt />
                          </button>

                          <button
                            className='btn btn-danger btn-sm ms-1 rounded-pill'
                            onClick={(event) => { (window.confirm('Desea eliminar el contrato ')) ? deleteHandler(rowData._id) : (event.preventDefault()) }}
                            disabled={isLoadingDelete}
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Eliminar el Contrato"
                          >
                            {isLoadingDelete ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : (
                              <span>
                                <FaTrash />
                              </span>
                            )}
                          </button>

                        </div>
                      </td>
                    </tr>

                  )
                  )
                )
              }
            </TableBody>
          </Table>
        </DatatableWrapper>) : ''}

    </div>
  )
}

export default ViewOrganizationLicenses
