import { Spinner, Message } from "..";
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { inputTextDisabled } from "../../utils/dynamicForm";
import useEventsHook from '../../api/event-organizations'




// Iframe Google Maps - https://developers.google.com/maps/documentation/embed/embedding-map?hl=es-419
const FormEventIndividuals = ({
  id,
  edit,
  formCleanHandler,
  isLoading,
  latitude,
  isSuccess,
  longitude,
  register,
  isError,
  errors,
  error,
  imagen,
  usuario,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = () => setShowVideo(!showVideo);
  const [isDragging, setIsDragging] = useState(false);
  const [mostrarCuadro, setMostrarCuadro] = useState(false);
  const [mostrarCuadroB, setMostrarCuadroB] = useState(false);
  const [mostrarCuadroC, setMostrarCuadroC] = useState(false);
  const audioRef = useRef(null);
  const audioRefB = useRef(null);
  const audioRefC = useRef(null);

  // Estos manejadores controlan el estado de 'isDragging'
  const handleIframeMouseDown = () => setIsDragging(true);
  const handleIframeMouseUp = () => setIsDragging(false);

  const cerrarVideo = () => {
    setShowVideo(false);
    setMostrarCuadro(false);
    setMostrarCuadroB(false);
    setMostrarCuadroC(false);
    formCleanHandler();
  };

  const manejarClickPolicia = () => {

    setMostrarCuadro(true); // Muestra el cuadro
    if (audioRef.current) {
      audioRef.current
        .play()
        .catch((error) =>
          console.error("Error al reproducir el audio:", error)
        );
    }
  };
  const manejarClickBomberos = () => {

    setMostrarCuadroB(true); // Muestra el cuadro
    if (audioRefB.current) {
      audioRefB.current
        .play()
        .catch((error) =>
          console.error("Error al reproducir el audio:", error)
        );
    }
  };
  const manejarClickCruz = () => {

    setMostrarCuadroC(true); // Muestra el cuadro
    if (audioRefC.current) {
      audioRefC.current
        .play()
        .catch((error) =>
          console.error("Error al reproducir el audio:", error)
        );
    }
  };


  const detenerAudioYCerrar = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pausa el audio
      audioRef.current.currentTime = 0; // Opcional: Restablece el tiempo a 0
    }
    setMostrarCuadro(false); // Aquí asumo que tienes un estado que controla la visibilidad
  };

  const detenerAudioYCerrarB = () => {
    if (audioRefB.current) {
      audioRefB.current.pause(); // Pausa el audio
      audioRefB.current.currentTime = 0; // Opcional: Restablece el tiempo a 0
    }
    setMostrarCuadroB(false); // Aquí asumo que tienes un estado que controla la visibilidad
  };

  const detenerAudioYCerrarC = () => {
    if (audioRefC.current) {
      audioRefC.current.pause(); // Pausa el audio
      audioRefC.current.currentTime = 0; // Opcional: Restablece el tiempo a 0
    }
    setMostrarCuadroC(false); // Aquí asumo que tienes un estado que controla la visibilidad
  };


  useEffect(() => {
    // Inicializar el objeto de audio al montar el componente
    audioRef.current = new Audio("/POLICE.mp3");
    audioRef.current.load(); // Precarga el audio
    audioRefB.current = new Audio("/FIRSIREN.mp3");
    audioRefB.current.load(); // Precarga el audio
    audioRefC.current = new Audio("/red-alert.mp3");
    audioRefC.current.load(); // Precarga el audio
  }, []);
  const estilosCuadroCentral = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "300px", // Asegura un ancho mínimo, pero puedes ajustarlo según necesites
    minHeight: "400px", // Asegura un alto mínimo, ajusta según el contenido
    padding: "20px",
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    zIndex: 1000,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  const estilosEncabezado = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: "20px", // Ajusta según sea necesario
  };

  const estilosLogo = {
    height: "100px", // Ajusta según sea necesario
    width: "100px", // Ajusta según sea necesario
    marginRight: "10px", // Espacio entre el logo y el texto
  };
  const sendWhatsAppNotification = async () => {
    const postData = {
      template: "testing",
      data: [
        `Mensaje de alerta, ${usuario} esta en peligro su ubicacion es: https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
      ],
      phones: [
        { number: "593983498106" },
        { number: "593985589228" },
        { number: "593992923522" },
      ],
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      };

      const response = await fetch(
        "https://aiot.constecoin.com/api/notificationWhatsapp/sendWhatsapp",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("La solicitud falló con el estado " + response.status);
      }
      // alert('Notificación de WhatsApp enviada con éxito!');
    } catch (error) {
      console.error("Error al enviar la notificación de WhatsApp:", error);
      // alert('Error al enviar la notificación de WhatsApp.');
    }
  };

  
  const [check911, setCheck911] = useState(false);
  const [state, setState] = useState('');
  const [detail, setDetail] = useState('');



  const handleChangeLlamar911 = (event) => {
    setCheck911(event.target.checked);
  }

  const handleState = (e) => {
    console.log(e);
    setState(e.target.value);
  }

  const handleDetail = event => {
    setDetail(event.target.value);
  }
  
  const { updateEvent } = useEventsHook({page : 1})

  const handleClick = () => {

  if(!check911){
    alert('Seleccione llamar al 911'); 
    return false;
  }

  if(state.length==0){
    alert('Seleccione un estado de cierre del evento'); 
    return false;
  }

  if(detail.length==0){
    alert('Ingrese detalle del evento'); 
    return false;
  }

  //data-bs-dismiss="modal"
    mutateAsyncUpdate({
      _id: id,
      state:state,
      detail:detail,
    })
  }

  const {   mutateAsync: mutateAsyncUpdate } = updateEvent

  return (
    <div
      className="modal fade"
      id="eventModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="eventModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-grande">
        <div className="modal-content modal-background">
          <div className="modal-header">
            <h4 className="modal-title " id="eventModalLabel">
              {edit ? "Generación Reporte del Evento" : "Publicar Evento"}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cerrarVideo}
            ></button>
          </div>
          <div className="modal-body">
            
            <div className="row">
              <div className="col-md-4">

                {isSuccess && (
                  <Message variant='success'>
                  El evento se ha actualizado correctamente.
                  </Message>
                )}
                
                {isLoading ? (
                  <Spinner />
                ) : isError ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <form >
                    <div className="row" style={{ color: "red" }}>
                      <center>
                        <h6 className=" text-center">USUARIO SOLICITANTE</h6>
                      </center>
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={imagen}
                          alt="Descripción de la imagen"
                          style={{ width: "50%", height: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 ">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Usuario:",
                          name: "user",
                          placeholder: "Usuario",
                        })}
                      </div>

                      <div className="col-md-4">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Ubicación:",
                          name: "locationName",
                          placeholder: "",
                        })}
                      </div>
                      <div className="col-md-4">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Sector:",
                          name: "locationSector",
                          placeholder: "",
                        })}
                      </div>

                      <div className="col-md-4">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Celular:",
                          name: "phone",
                          placeholder: "",
                        })}
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="row">Últimas coordenadas:</div>
                          <div className="row">
                            <div className="col">
                              <b>Lat:</b>
                              {latitude}
                            </div>
                            <div className="col">
                              <b>Long:</b>
                              {longitude}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">Enviar Información </div>
                        <div className="row">
                          <div className="accordion-body">
                            <div
                              className="btn-group"
                              role="group">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={sendWhatsAppNotification}
                              >
                                WhatsApp
                              </button>
                              {/* <button type="button" className="btn btn-warning">
                                SMS
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <div className="col-md-4">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Servicio:",
                          name: "service",
                          placeholder: "Servicio",
                        })}
                      </div>
                      <div className="col-md-4">
                        {inputTextDisabled({
                          register,
                          errors,
                          label: "Red:",
                          name: "network",
                          placeholder: "Red",
                        })}
                      </div>
                      <div className="col-md-4">
                        
                      </div>    
                      <div className="col-md-6 col-12">
                       
                      </div>

                      
                    </div>
                  </form>
                )}
              </div>
              <div className="col-md-4">
                {/* Primer Mapa */}
                <div
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                    marginBottom: "20px", // Agrega un margen para separar los mapas
                  }}
                >
                  <iframe
                    width="100%"
                    height="400" // Altura ajustada previamente
                    loading="lazy"
                    allow="accelerometer; gyroscope;"
                    referrerPolicy="no-referrer"
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${latitude},${longitude}`}
                    style={{
                      border: 0,
                      maxWidth: "1500px", // Ajusta el ancho máximo aquí
                      margin: "auto",
                    }}
                  ></iframe>
                </div>

                {/* Segundo Mapa */}
                <div
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative", // Esto asegura que el div hijo absoluto se posicione relativo a este contenedor
                    height: "400px", // Asegura que este contenedor tenga una altura definida
                  }}
                >
                  <div
                    onMouseDown={handleIframeMouseDown}
                    onMouseUp={handleIframeMouseUp}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "400px",
                      width: "100%", // Hace que el div cubra el ancho del contenedor padre
                      maxWidth: "1500px", // Limita el ancho máximo del div
                      cursor: isDragging ? "grabbing" : "grab",
                      pointerEvents: isDragging ? "none" : "auto",
                      margin: "auto", // Centra el div en caso de que el contenedor padre sea más ancho
                    }}
                  >
                    <iframe
                      width="100%"
                      height="400px"
                      loading="lazy"
                      allow="accelerometer; gyroscope;"
                      referrerPolicy="no-referrer"
                      src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.REACT_APP_GOOGLE_API_KEY}&location=${latitude},${longitude}&heading=210&pitch=10&fov=100`}
                      style={{
                        border: 0,
                        pointerEvents: "all",
                        maxWidth: "1500px",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Procedimientos de Emergencia
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <ul className="list-group">
                          <li className="list-group-item">
                            <input
                              className="form-check-input"
                              type="checkbox" 
                              value=""
                              id="check911"
                              onChange={handleChangeLlamar911}
                            ></input>
                            <label
                              className="form-check-label"
                              htmlFor="check911"
                            >
                              &nbsp;&nbsp;&nbsp; Llamar al 911
                            </label>
                          </li>
                          <li className="list-group-item">
                            <div className="col-md-12">
                                Registre el evento
                            </div>
                            <div className="col-md-12">
                              <textarea
                                  id="detail"
                                  name="detail"
                                  rows={8}
                                  cols={45}
                                  onChange={handleDetail}
                                  value={detail}
                                />
                            </div>
                          </li>
                          <li className="list-group-item">
                          
                            Cierre el evento &nbsp;&nbsp;&nbsp;   
                            <select name='state' value={state} onChange={handleState}>
                            <option value="">Seleccione una opcion</option>
                              <option value="Atendida">Atendida</option>
                              <option value="Asignada">Asignada</option>
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Entidades de Ayuda
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="btn-group" role="group" aria-label="xxxx">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={manejarClickPolicia}
                        >
                          Policia
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={manejarClickBomberos}
                        >
                          Bomberos
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={manejarClickCruz}
                        >
                          Cruz Roja
                        </button>
                      </div>
                    </div>

                    {mostrarCuadro && (
                      <div style={estilosCuadroCentral}>
                        <div style={estilosEncabezado}>
                          <img
                            src="https://seeklogo.com/images/P/policia-nacional-del-ecuador-logo-3A3E894039-seeklogo.com.png"
                            alt="Logo Policía Nacional"
                            style={estilosLogo}
                          />
                          <h3>Comunicándose con POLICIA NACIONAL</h3>
                        </div>
                        <img
                          src="https://images.emojiterra.com/google/noto-emoji/unicode-15/animated/1f6a8.gif"
                          alt="GIF de comunicación"
                          style={{
                            height: "90px",
                            width: "90px",
                            alignSelf: "center",
                          }}
                        />
                        <button
                          onClick={detenerAudioYCerrar}
                          style={{
                            alignSelf: "center",
                            marginTop: "50px",
                            backgroundColor: "#f04",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontWeight: "bold",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Colgar
                        </button>
                      </div>
                    )}
                    {mostrarCuadroB && (
                      <div style={estilosCuadroCentral}>
                        <div style={estilosEncabezado}>
                          <img
                            src="https://scontent.fuio10-1.fna.fbcdn.net/v/t1.6435-9/121223625_713071432629609_3460184438956629297_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=7f8c78&_nc_eui2=AeHqHpyuejW4tcZ3Vq_Nh6TNGa5VBm1471oZrlUGbXjvWt7ZHNMqvrXm0mqxqdilMBFCo9k0HDlghoF0sCpvScd4&_nc_ohc=grrR8H63DtgAX_fUXc3&_nc_oc=AQkswpylsV5YbjS-teFrP-yNRuP8f2RHZjmxjDTPuhAOqIil3kAMDB3GCY_nWpDP9OY&_nc_ht=scontent.fuio10-1.fna&oh=00_AfBZ5-1JfJ-qYJTfQpBrjBNNHsaAL6uam6k6bsetISz_oQ&oe=65F510F0"
                            alt="Logo Policía Nacional"
                            style={estilosLogo}
                          />
                          <h3>Comunicándose con BOMBEROS ECUADOR</h3>
                        </div>
                        <img
                          src="https://images.emojiterra.com/google/noto-emoji/unicode-15/animated/1f6a8.gif"
                          alt="GIF de comunicación"
                          style={{
                            height: "90px",
                            width: "90px",
                            alignSelf: "center",
                          }}
                        />
                        <button
                          onClick={detenerAudioYCerrarB}
                          style={{
                            alignSelf: "center",
                            marginTop: "50px",
                            backgroundColor: "#f04",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontWeight: "bold",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Colgar
                        </button>
                      </div>
                    )}
                    {mostrarCuadroC && (
                      <div style={estilosCuadroCentral}>
                        <div style={estilosEncabezado}>
                          <img
                            src="https://scontent.fuio10-1.fna.fbcdn.net/v/t39.30808-6/347130043_949348192782345_6715259725257183727_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeGtWmV18DSvSDGHdlDrkZXKdpxiOd50kJ52nGI53nSQnvP7b9ZjDDJFmgQWOx-9ipgxl16rmCFR8imwZpz7Exs9&_nc_ohc=8sYS0nxiW6QAX-a5Avl&_nc_ht=scontent.fuio10-1.fna&oh=00_AfBNwfjRf5Q_Yw-NGC2xWpiD01zH_-Dmaw8NUzVOuBEv9A&oe=65D26ED1"
                            alt="Logo Policía Nacional"
                            style={estilosLogo}
                          />
                          <h3>Comunicándose con CRUZ ROJA ECUADOR</h3>
                        </div>
                        <img
                          src="https://images.emojiterra.com/google/noto-emoji/unicode-15/animated/1f6a8.gif"
                          alt="GIF de comunicación"
                          style={{
                            height: "90px",
                            width: "90px",
                            alignSelf: "center",
                          }}
                        />
                        <button
                          onClick={detenerAudioYCerrarC}
                          style={{
                            alignSelf: "center",
                            marginTop: "50px",
                            backgroundColor: "#f04",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontWeight: "bold",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Colgar
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Camaras Cercanas (1)
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <button onClick={toggleVideo}>
                        {" "}
                        {showVideo ? "Ocultar video" : "Ver video"}
                      </button>
                      {showVideo && (
                        <div
                          style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Asegura que el contenido (video) también esté centrado verticalmente.
                            zIndex: 1000, // Asegura que el contenedor esté por encima de otros elementos.
                          }}
                        >
                          <button
                            onClick={() => setShowVideo(false)}
                            style={{
                              position: "absolute",
                              top: "-30px", // Ajusta según necesidad
                              right: "20px",
                              zIndex: 1051, // Asegurar que el botón esté por encima del video
                              background: "#f00",
                              color: "#fff",
                              border: "none",
                              borderRadius: "50%",
                              width: "80px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            Cerrar
                          </button>
                          <ReactPlayer
                            url="https://aiot.constecoin.com/repositorio/video/public/hls/index.m3u8"
                            playing={showVideo}
                            controls
                            width="90vw"
                            height="90vh"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  

                </div>


              </div>
            </div>
            
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={formCleanHandler}
            >
              Salir
            </button>
            
            <button
              onClick={handleClick}
              className="btn btn-primary">Enviar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEventIndividuals;
